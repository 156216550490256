// note: the id's are no longer used for classification, but are kept here for compatibility with the old code

export default {
  0: 'ACL tear (anterior cruciate ligament tear), bilateral',
  1: 'ACL tear (anterior cruciate ligament tear), left',
  2: 'ACL tear (anterior cruciate ligament tear), right',
  3: 'acne',
  4: 'adjustment disorder',
  5: 'agoraphobia',
  6: 'alopecia',
  7: 'ALS (amyotrophic lateral sclerosis)',
  8: 'anal cancer',
  9: 'anal or perianal fistula (fistula-in-ano)',
  10: 'ankle replacement (ankle arthroplasty), bilateral',
  11: 'ankle replacement (ankle arthroplasty), left',
  12: 'ankle replacement (ankle arthroplasty), right',
  13: 'ankle sprain, bilateral',
  14: 'ankle sprain, left',
  15: 'ankle sprain, right',
  16: 'ankylosis in ankle, bilateral',
  17: 'ankylosis in ankle, left',
  18: 'ankylosis in ankle, right',
  19: 'ankylosis in elbow, bilateral',
  20: 'ankylosis in elbow, left',
  21: 'ankylosis in elbow, right',
  22: 'ankylosis in hand or fingers, bilateral',
  23: 'ankylosis in hand or fingers, left',
  24: 'ankylosis in hand or fingers, right',
  25: 'ankylosis in hip, bilateral',
  26: 'ankylosis in hip, left',
  27: 'ankylosis in hip, right',
  28: 'ankylosis in knee, bilateral',
  29: 'ankylosis in knee, left',
  30: 'ankylosis in knee, right',
  31: 'ankylosis in shoulder, bilateral',
  32: 'ankylosis in shoulder, left',
  33: 'ankylosis in shoulder, right',
  34: 'ankylosis in wrist, bilateral',
  35: 'ankylosis in wrist, left',
  36: 'ankylosis in wrist, right',
  37: 'anorexia (type of eating disorder)',
  38: 'aortic insufficiency (aortic regurgitation)',
  39: 'aortic valve disease',
  40: 'arrhythmia (irregular heartbeat)',
  41: 'asthma',
  42: 'astragalectomy or talectomy (removal of talus bone in ankle), bilateral',
  43: 'astragalectomy or talectomy (removal of talus bone in ankle), left',
  44: 'astragalectomy or talectomy (removal of talus bone in ankle), right',
  45: "athlete's foot (tinea pedis)",
  46: 'atrial fibrillation (A-fib)',
  47: 'basal cell carcinoma of the skin, extremities or trunk',
  48: 'basal cell carcinoma of the skin, head or neck',
  49: 'bipolar disorder',
  50: 'biventricular hypertrophy',
  51: 'bladder cancer',
  52: 'bladder stones',
  53: 'bone cancer, including osteosarcoma, extremities or trunk',
  54: 'bone cancer, including osteosarcoma, head or neck',
  55: 'Boutonniere deformity in fingers, bilateral',
  56: 'Boutonniere deformity in fingers, left',
  57: 'Boutonniere deformity in fingers, right',
  58: 'brain cancer, including glioblastoma',
  59: 'breast cancer, bilateral',
  60: 'breast cancer, left',
  61: 'breast cancer, right',
  62: 'bronchiectasis',
  63: 'bulimia (type of eating disorder)',
  64: 'bunions (hallux valgus), bilateral',
  65: 'bunions (hallux valgus), left',
  66: 'bunions (hallux valgus), right',
  67: 'bursitis in ankle, bilateral',
  68: 'bursitis in ankle, left',
  69: 'bursitis in ankle, right',
  70: 'bursitis in elbow, bilateral',
  71: 'bursitis in elbow, left',
  72: 'bursitis in elbow, right',
  73: 'bursitis in foot, bilateral',
  74: 'bursitis in foot, left',
  75: 'bursitis in foot, right',
  76: 'bursitis in hand or fingers, bilateral',
  77: 'bursitis in hand or fingers, left',
  78: 'bursitis in hand or fingers, right',
  79: 'bursitis in hip, bilateral',
  80: 'bursitis in hip, left',
  81: 'bursitis in hip, right',
  82: 'bursitis in knee, bilateral',
  83: 'bursitis in knee, left',
  84: 'bursitis in knee, right',
  85: 'bursitis in shoulder, bilateral',
  86: 'bursitis in shoulder, left',
  87: 'bursitis in shoulder, right',
  88: 'bursitis in wrist, bilateral',
  89: 'bursitis in wrist, left',
  90: 'bursitis in wrist, right',
  91: 'carpal tunnel syndrome, bilateral',
  92: 'carpal tunnel syndrome, left',
  93: 'carpal tunnel syndrome, right',
  94: 'cervical cancer',
  95: 'cervical spinal stenosis (narrowing of spinal canal in neck)',
  96: 'chloracne',
  97: 'chronic bronchitis',
  98: 'chronic conjunctivitis (pink eye)',
  99: 'chronic fatigue syndrome',
  100: 'chronic kidney disease (CKD)',
  101: 'chronic laryngitis',
  102: 'chronic nephritis (inflammation of kidneys)',
  103: 'chronic obstructive pulmonary disease (COPD)',
  104: "chronic otitis externa (swimmer's ear), bilateral",
  105: "chronic otitis externa (swimmer's ear), left",
  106: "chronic otitis externa (swimmer's ear), right",
  107: 'chronic otitis media (chronic middle ear infection), bilateral',
  108: 'chronic otitis media (chronic middle ear infection), left',
  109: 'chronic otitis media (chronic middle ear infection), right',
  110: 'chronic rhinitis, allergic or non-allergic',
  111: 'chronic sinusitis',
  112: 'chronic suppurative otitis media (CSOM) or mastoiditis, bilateral',
  113: 'chronic suppurative otitis media (CSOM) or mastoiditis, left',
  114: 'chronic suppurative otitis media (CSOM) or mastoiditis, right',
  115: 'chronic urticaria (chronic hives)',
  116: 'cirrhosis (scarring of liver)',
  117: 'claw foot (pes cavus), bilateral',
  118: 'claw foot (pes cavus), left',
  119: 'claw foot (pes cavus), right',
  120: 'colorectal cancer or colon cancer',
  121: 'complete loss of sense of smell (anosmia)',
  122: 'complete loss of sense of taste (ageusia)',
  123: 'congestive heart failure (CHF)',
  124: 'constrictive bronchiolitis (obliterative bronchiolitis)',
  125: 'coronary artery disease (CAD or arteriosclerotic heart disease)',
  126: 'costochondritis',
  127: 'Cranial nerve paralysis or cranial neuritis (inflammation of cranial nerves)',
  128: 'cranial neuralgia (cranial nerve pain)',
  129: "Crohn's disease",
  130: 'cyclothymic disorder (mild bipolar disorder)',
  131: "De Quervain's tenosynovitis (De Quervain's syndrome), bilateral",
  132: "De Quervain's tenosynovitis (De Quervain's syndrome), left",
  133: "De Quervain's tenosynovitis (De Quervain's syndrome), right",
  134: 'decompression illness',
  135: 'deep vein thrombosis (DVT) in leg, bilateral',
  136: 'deep vein thrombosis (DVT) in leg, left',
  137: 'deep vein thrombosis (DVT) in leg, right',
  138: 'degenerative arthritis (osteoarthritis) in ankle, bilateral',
  139: 'degenerative arthritis (osteoarthritis) in ankle, left',
  140: 'degenerative arthritis (osteoarthritis) in ankle, right',
  141: 'degenerative arthritis (osteoarthritis) in elbow, bilateral',
  142: 'degenerative arthritis (osteoarthritis) in elbow, left',
  143: 'degenerative arthritis (osteoarthritis) in elbow, right',
  144: 'degenerative arthritis (osteoarthritis) in foot, bilateral',
  145: 'degenerative arthritis (osteoarthritis) in foot, left',
  146: 'degenerative arthritis (osteoarthritis) in foot, right',
  147: 'degenerative arthritis (osteoarthritis) in hand or fingers, bilateral',
  148: 'degenerative arthritis (osteoarthritis) in hand or fingers, left',
  149: 'degenerative arthritis (osteoarthritis) in hand or fingers, right',
  150: 'degenerative arthritis (osteoarthritis) in hip, bilateral',
  151: 'degenerative arthritis (osteoarthritis) in hip, left',
  152: 'degenerative arthritis (osteoarthritis) in hip, right',
  153: 'degenerative arthritis (osteoarthritis) in knee, bilateral',
  154: 'degenerative arthritis (osteoarthritis) in knee, left',
  155: 'degenerative arthritis (osteoarthritis) in knee, right',
  156: 'degenerative arthritis (osteoarthritis) in shoulder, bilateral',
  157: 'degenerative arthritis (osteoarthritis) in shoulder, left',
  158: 'degenerative arthritis (osteoarthritis) in shoulder, right',
  159: 'degenerative arthritis (osteoarthritis) in wrist, bilateral',
  160: 'degenerative arthritis (osteoarthritis) in wrist, left',
  161: 'degenerative arthritis (osteoarthritis) in wrist, right',
  162: 'depression (major depressive disorder)',
  163: 'deviated septum',
  164: 'diabetes insipidus',
  165: 'diabetes, type 1 or type 2',
  166: 'diabetic nephropathy (diabetic kidney disease)',
  167: 'diabetic peripheral neuropathy, bilateral lower extremities',
  168: 'diabetic peripheral neuropathy, bilateral upper extremities',
  169: 'diabetic peripheral neuropathy, left lower extremities',
  170: 'diabetic peripheral neuropathy, left upper extremities',
  171: 'diabetic peripheral neuropathy, right lower extremities',
  172: 'diabetic peripheral neuropathy, right upper extremities',
  173: 'diabetic retinopathy',
  174: 'dry eye syndrome',
  175: "Dupuytren's contracture (abnormal thickening of tissues in palm of hand), bilateral",
  176: "Dupuytren's contracture (abnormal thickening of tissues in palm of hand), left",
  177: "Dupuytren's contracture (abnormal thickening of tissues in palm of hand), right",
  178: 'dysphagia (difficulty swallowing), associated with neurological disorder',
  179: 'dysphagia (difficulty swallowing), not associated with neurological disorder',
  180: 'ear cancer',
  181: 'eczema (atopic dermatitis)',
  182: 'elbow replacement (elbow arthroplasty), bilateral',
  183: 'elbow replacement (elbow arthroplasty), left',
  184: 'elbow replacement (elbow arthroplasty), right',
  185: 'emphysema',
  186: 'endometriosis',
  187: 'enlarged prostate (benign prostatic hyperplasia or BPH)',
  188: 'epididymitis',
  189: 'epiphora (watery eyes)',
  190: 'erectile dysfunction (ED)',
  191: 'esophageal cancer',
  192: 'esophageal stricture (narrowing of esophagus)',
  193: 'eye cancer, including melanoma of the eye',
  194: 'fecal incontinence (loss of bowel control)',
  195: 'female sexual arousal disorder (FSAD)',
  196: 'femoral hernia (hernia in thigh area)',
  197: 'fibromyalgia',
  198: 'flatfoot (pes planus), bilateral',
  199: 'flatfoot (pes planus), left',
  200: 'flatfoot (pes planus), right',
  201: 'foot fracture (tarsal or metatarsal fracture), bilateral',
  202: 'foot fracture (tarsal or metatarsal fracture), left',
  203: 'foot fracture (tarsal or metatarsal fracture), right',
  204: 'frostbite in foot, bilateral',
  205: 'frostbite in foot, left',
  206: 'frostbite in foot, right',
  207: 'frostbite in hand or fingers, bilateral',
  208: 'frostbite in hand or fingers, left',
  209: 'frostbite in hand or fingers, right',
  210: 'gallstones (cholelithiasis)',
  211: "gamekeeper's thumb, bilateral",
  212: "gamekeeper's thumb, left",
  213: "gamekeeper's thumb, right",
  214: 'ganglion cyst in hand or fingers, bilateral',
  215: 'ganglion cyst in hand or fingers, left',
  216: 'ganglion cyst in hand or fingers, right',
  217: 'ganglion cyst in wrist, bilateral',
  218: 'ganglion cyst in wrist, left',
  219: 'ganglion cyst in wrist, right',
  220: 'generalized anxiety disorder (GAD)',
  221: 'GERD (gastroesophageal reflux disease)',
  222: 'glaucoma',
  223: "golfer's elbow (medial epicondylitis), bilateral",
  224: "golfer's elbow (medial epicondylitis), left",
  225: "golfer's elbow (medial epicondylitis), right",
  226: 'gonococcal arthritis',
  227: 'gout in foot, bilateral',
  228: 'gout in foot, left',
  229: 'gout in foot, right',
  230: 'gout in wrist, bilateral',
  231: 'gout in wrist, left',
  232: 'gout in wrist, right',
  233: 'granulomatous disease',
  234: 'greater trochanteric pain syndrome (lateral hip pain), bilateral',
  235: 'greater trochanteric pain syndrome (lateral hip pain), left',
  236: 'greater trochanteric pain syndrome (lateral hip pain), right',
  237: 'hallux rigidus (big toe arthritis), bilateral',
  238: 'hallux rigidus (big toe arthritis), left',
  239: 'hallux rigidus (big toe arthritis), right',
  240: 'hammer toe, bilateral',
  241: 'hammer toe, left',
  242: 'hammer toe, right',
  243: "Hansen's disease (leprosy)",
  244: 'hearing loss',
  245: 'heart attack (myocardial infarction)',
  246: 'heart bypass surgery (coronary artery bypass graft)',
  247: 'hemorrhoids',
  248: 'hepatitis B',
  249: 'hepatitis C',
  250: 'herpes (herpes simplex virus or HSV)',
  251: 'hiatal hernia',
  252: 'hip impingement (femoroacetabular impingement or FAI), bilateral',
  253: 'hip impingement (femoroacetabular impingement or FAI), left',
  254: 'hip impingement (femoroacetabular impingement or FAI), right',
  255: 'hip replacement (hip arthroplasty), bilateral',
  256: 'hip replacement (hip arthroplasty), left',
  257: 'hip replacement (hip arthroplasty), right',
  258: 'hip sprain, bilateral',
  259: 'hip sprain, left',
  260: 'hip sprain, right',
  261: 'HIV-related illness',
  262: 'Hodgkin lymphoma',
  263: 'hydrocele, right',
  264: 'hyperhidrosis (excessive sweating)',
  265: 'hypertension (high blood pressure)',
  266: 'hyperthyroidism (overactive thyroid)',
  267: 'hypopharyngeal cancer',
  268: 'hypothyroidism (underactive thyroid)',
  269: 'hysterectomy (removal of uterus)',
  270: 'hysterectomy and ovariectomy (removal of uterus and both ovaries)',
  271: 'illness anxiety disorder (hypochondria)',
  272: 'immersion foot, bilateral',
  273: 'immersion foot, left',
  274: 'immersion foot, right',
  275: 'inguinal hernia (hernia in groin)',
  276: 'interstitial lung disease (ILD), including asbestosis',
  277: 'intervertebral disc syndrome (IVDS), back',
  278: 'intervertebral disc syndrome (IVDS), neck',
  279: 'iron deficiency anemia',
  280: 'irritable bowel syndrome (IBS)',
  281: 'jaw cancer',
  282: 'keratinization skin disorders',
  283: 'kidney cancer (renal cancer), bilateral',
  284: 'kidney cancer (renal cancer), left',
  285: 'kidney cancer (renal cancer), right',
  286: 'kidney removal (nephrectomy)',
  287: 'kidney stones (nephrolithiasis)',
  288: 'kidney transplant',
  289: 'knee hyperextension (genu recurvatum), bilateral',
  290: 'knee hyperextension (genu recurvatum), left',
  291: 'knee hyperextension (genu recurvatum), right',
  292: 'knee instability, bilateral',
  293: 'knee instability, left',
  294: 'knee instability, right',
  295: 'knee or patellar dislocation, bilateral',
  296: 'knee or patellar dislocation, left',
  297: 'knee or patellar dislocation, right',
  298: 'knee or patellar fracture, bilateral',
  299: 'knee or patellar fracture, left',
  300: 'knee or patellar fracture, right',
  301: 'knee replacement (knee arthroplasty), bilateral',
  302: 'knee replacement (knee arthroplasty), left',
  303: 'knee replacement (knee arthroplasty), right',
  304: 'knee strain or sprain, bilateral',
  305: 'knee strain or sprain, left',
  306: 'knee strain or sprain, right',
  307: 'labyrinthitis (inner ear inflammation), bilateral',
  308: 'labyrinthitis (inner ear inflammation), left',
  309: 'labyrinthitis (inner ear inflammation), right',
  310: 'laryngeal cancer (cancer of larynx)',
  311: 'left ventricular hypertrophy (LVH)',
  312: 'leukemia',
  313: 'liver cancer, including hepatocellular carcinoma (HCC)',
  314: 'loss of both eyes',
  315: 'loss of teeth due to bone loss',
  316: 'lower back sprain (lumbosacral sprain)',
  317: 'lower back strain (lumbosacral strain)',
  318: 'lumbar spinal stenosis (narrowing of spinal canal in lower back)',
  319: 'lung cancer',
  320: 'lupus',
  321: 'lymphatic filariasis',
  322: 'malaria',
  323: 'malignant tumor in spinal cord',
  324: 'mallet finger, bilateral',
  325: 'mallet finger, left',
  326: 'mallet finger, right',
  327: 'melanoma of the skin',
  328: "Meniere's disease",
  329: 'meniscectomy (removal of meniscus), bilateral',
  330: 'meniscectomy (removal of meniscus), left',
  331: 'meniscectomy (removal of meniscus), right',
  332: 'meniscus tear, bilateral',
  333: 'meniscus tear, left',
  334: 'meniscus tear, right',
  335: 'metatarsalgia (pain in ball of foot), bilateral',
  336: 'metatarsalgia (pain in ball of foot), left',
  337: 'metatarsalgia (pain in ball of foot), right',
  338: 'middle back sprain (thoracic sprain)',
  339: 'middle back strain (thoracic strain)',
  340: 'migraines (headaches)',
  341: 'mitral regurgitation',
  342: 'mitral valve prolapse (Barlow syndrome)',
  343: "Morton's neuroma, bilateral",
  344: "Morton's neuroma, left",
  345: "Morton's neuroma, right",
  346: 'multiple myeloma, extremities or trunk',
  347: 'multiple myeloma, head or neck',
  348: 'multiple sclerosis (MS)',
  349: 'muscle hernia',
  350: 'myasthenia gravis',
  351: 'nasopharyngeal cancer',
  352: 'neck sprain (cervical sprain)',
  353: 'neck strain (cervical strain)',
  354: 'nerve-related soft-tissue sarcoma, extremities or trunk',
  355: 'nerve-related soft-tissue sarcoma, head or neck',
  356: 'neurogenic bladder',
  357: 'non-diabetic peripheral neuropathy, bilateral lower extremities',
  358: 'non-diabetic peripheral neuropathy, bilateral upper extremities',
  359: 'non-diabetic peripheral neuropathy, left lower extremities',
  360: 'non-diabetic peripheral neuropathy, left upper extremities',
  361: 'non-diabetic peripheral neuropathy, right lower extremities',
  362: 'non-diabetic peripheral neuropathy, right upper extremities',
  363: 'non-Hodgkin lymphoma',
  364: 'obsessive compulsive disorder (OCD)',
  365: 'oral cavity squamous cell carcinoma',
  366: 'orchitis (inflammation of testicles)',
  367: 'oropharyngeal cancer',
  368: 'osteomyelitis',
  369: 'ovarian adhesions',
  370: 'ovarian cancer, bilateral',
  371: 'ovarian cancer, left',
  372: 'ovarian cancer, right',
  373: 'ovarian cysts',
  374: 'ovariectomy (removal of one or both ovaries)',
  375: 'pancreatic cancer',
  376: 'panic disorder',
  377: 'paranasal sinus or nasal cavity cancer',
  378: "Parkinson's disease",
  379: 'patellar or quadriceps tendon rupture, bilateral',
  380: 'patellar or quadriceps tendon rupture, left',
  381: 'patellar or quadriceps tendon rupture, right',
  382: 'patellofemoral pain syndrome, bilateral',
  383: 'patellofemoral pain syndrome, left',
  384: 'patellofemoral pain syndrome, right',
  385: 'PCL tear (posterior cruciate ligament tear), bilateral',
  386: 'PCL tear (posterior cruciate ligament tear), left',
  387: 'PCL tear (posterior cruciate ligament tear), right',
  388: 'penile cancer',
  389: 'perforated eardrum (perforated tympanic membrane), bilateral',
  390: 'perforated eardrum (perforated tympanic membrane), left',
  391: 'perforated eardrum (perforated tympanic membrane), right',
  392: 'persistent depressive disorder (dysthymic disorder)',
  393: 'pharyngeal cancer (throat cancer)',
  394: 'plantar fasciitis, bilateral',
  395: 'plantar fasciitis, left',
  396: 'plantar fasciitis, right',
  397: 'plantar warts (foot warts), bilateral',
  398: 'plantar warts (foot warts), left',
  399: 'plantar warts (foot warts), right',
  400: 'pleurisy (pleuritis)',
  401: 'pneumococcal arthritis',
  402: 'polycystic ovary syndrome (PCOS)',
  403: 'post-traumatic arthritis in ankle, bilateral',
  404: 'post-traumatic arthritis in ankle, left',
  405: 'post-traumatic arthritis in ankle, right',
  406: 'post-traumatic arthritis in elbow, bilateral',
  407: 'post-traumatic arthritis in elbow, left',
  408: 'post-traumatic arthritis in elbow, right',
  409: 'post-traumatic arthritis in foot, bilateral',
  410: 'post-traumatic arthritis in foot, left',
  411: 'post-traumatic arthritis in foot, right',
  412: 'post-traumatic arthritis in hand or fingers, bilateral',
  413: 'post-traumatic arthritis in hand or fingers, left',
  414: 'post-traumatic arthritis in hand or fingers, right',
  415: 'post-traumatic arthritis in hip, bilateral',
  416: 'post-traumatic arthritis in hip, left',
  417: 'post-traumatic arthritis in hip, right',
  418: 'post-traumatic arthritis in knee, bilateral',
  419: 'post-traumatic arthritis in knee, left',
  420: 'post-traumatic arthritis in knee, right',
  421: 'post-traumatic arthritis in shoulder, bilateral',
  422: 'post-traumatic arthritis in shoulder, left',
  423: 'post-traumatic arthritis in shoulder, right',
  424: 'post-traumatic arthritis in wrist, bilateral',
  425: 'post-traumatic arthritis in wrist, left',
  426: 'post-traumatic arthritis in wrist, right',
  427: 'poststreptococcal arthritis',
  428: 'premature ventricular contractions',
  429: 'proctitis (inflammation of rectum)',
  430: 'prostate cancer',
  431: 'prostatitis (inflammation of prostate)',
  432: 'pseudofolliculitis barbae (razor bumps, shave bumps, or ingrown hairs)',
  433: 'psoriasis',
  434: 'PTSD (post-traumatic stress disorder)',
  435: 'pulmonary fibrosis',
  436: 'pulmonary granuloma (lung nodule)',
  437: 'radiculopathy, bilateral lower extremities',
  438: 'radiculopathy, bilateral upper extremities',
  439: 'radiculopathy, left lower extremities',
  440: 'radiculopathy, left upper extremities',
  441: 'radiculopathy, right lower extremities',
  442: 'radiculopathy, right upper extremities',
  443: "Raynaud's disease",
  444: 'rheumatoid arthritis',
  445: 'rib fracture, bilateral',
  446: 'rib fracture, left',
  447: 'rib fracture, right',
  448: 'rib removal',
  449: 'right ventricular hypertrophy (RVH)',
  450: 'ringworm (dermatophytosis)',
  451: 'rotator cuff tear, bilateral',
  452: 'rotator cuff tear, left',
  453: 'rotator cuff tear, right',
  454: 'salivary gland cancer',
  455: 'sarcoidosis',
  456: 'scars, extremities or trunk',
  457: 'scars, head, face or neck',
  458: 'schizophrenia',
  459: 'shin splints, bilateral',
  460: 'shin splints, left',
  461: 'shin splints, right',
  462: 'shoulder dislocation, bilateral',
  463: 'shoulder dislocation, left',
  464: 'shoulder dislocation, right',
  465: 'shoulder impingement syndrome, bilateral',
  466: 'shoulder impingement syndrome, left',
  467: 'shoulder impingement syndrome, right',
  468: 'shoulder replacement (shoulder arthroplasty), bilateral',
  469: 'shoulder replacement (shoulder arthroplasty), left',
  470: 'shoulder replacement (shoulder arthroplasty), right',
  471: 'shoulder strain, bilateral',
  472: 'shoulder strain, left',
  473: 'shoulder strain, right',
  474: 'sickle cell anemia',
  475: 'SLAP tear (superior labral anterior to posterior tear), bilateral',
  476: 'SLAP tear (superior labral anterior to posterior tear), right',
  477: 'SLAP tear (superior labral anterior to posterior), left',
  478: 'sleep apnea',
  479: 'small intestine cancer',
  480: 'soft-tissue sarcoma of muscle, fat, or fibrous connective tissue, extremities or trunk',
  481: 'soft-tissue sarcoma of muscle, fat, or fibrous connective tissue, head or neck',
  482: 'somatic symptom disorder (SSD)',
  483: 'spinal arthritis, back',
  484: 'spinal arthritis, neck',
  485: 'spondylolisthesis, back',
  486: 'spondylolisthesis, neck',
  487: 'squamous cell carcinoma of the skin, extremities or trunk',
  488: 'squamous cell carcinoma of the skin, head or neck',
  489: 'stomach cancer',
  490: 'stress fracture in leg, bilateral',
  491: 'stress fracture in leg, left',
  492: 'stress fracture in leg, right',
  493: 'syphilitic arthritis',
  494: 'tachycardia',
  495: 'tailbone (coccyx) removal',
  496: 'temporomandibular disorder, including TMJ',
  497: 'tendinosis in hand or fingers, bilateral',
  498: 'tendinosis in hand or fingers, left',
  499: 'tendinosis in hand or fingers, right',
  500: 'tendonitis (tendinitis) in ankle, bilateral',
  501: 'tendonitis (tendinitis) in ankle, left',
  502: 'tendonitis (tendinitis) in ankle, right',
  503: 'tendonitis (tendinitis) in elbow, bilateral',
  504: 'tendonitis (tendinitis) in elbow, left',
  505: 'tendonitis (tendinitis) in elbow, right',
  506: 'tendonitis (tendinitis) in foot, bilateral',
  507: 'tendonitis (tendinitis) in foot, left',
  508: 'tendonitis (tendinitis) in foot, right',
  509: 'tendonitis (tendinitis) in hand or fingers, bilateral',
  510: 'tendonitis (tendinitis) in hand or fingers, left',
  511: 'tendonitis (tendinitis) in hand or fingers, right',
  512: 'tendonitis (tendinitis) in hip, bilateral',
  513: 'tendonitis (tendinitis) in hip, left',
  514: 'tendonitis (tendinitis) in hip, right',
  515: 'tendonitis (tendinitis) in knee, bilateral',
  516: 'tendonitis (tendinitis) in knee, left',
  517: 'tendonitis (tendinitis) in knee, right',
  518: 'tendonitis (tendinitis) in shoulder, bilateral',
  519: 'tendonitis (tendinitis) in shoulder, left',
  520: 'tendonitis (tendinitis) in shoulder, right',
  521: 'tendonitis (tendinitis) in wrist, bilateral',
  522: 'tendonitis (tendinitis) in wrist, left',
  523: 'tendonitis (tendinitis) in wrist, right',
  524: 'tennis elbow (lateral epicondylitis), bilateral',
  525: 'tennis elbow (lateral epicondylitis), left',
  526: 'tennis elbow (lateral epicondylitis), right',
  527: 'tenosynovitis in hand or fingers, bilateral',
  528: 'tenosynovitis in hand or fingers, left',
  529: 'tenosynovitis in hand or fingers, right',
  530: 'tenosynovitis in wrist, bilateral',
  531: 'tenosynovitis in wrist, left',
  532: 'tenosynovitis in wrist, right',
  533: 'testicular cancer, bilateral',
  534: 'testicular cancer, left',
  535: 'testicular cancer, right',
  536: 'thyroid cancer',
  537: 'tibia or fibula fracture, bilateral',
  538: 'tibia or fibula fracture, left',
  539: 'tibia or fibula fracture, right',
  540: 'Tietze syndrome',
  541: 'tinnitus (ringing or hissing in ears)',
  542: 'tongue cancer',
  543: 'trachea cancer (cancer of windpipe)',
  544: 'traumatic brain injury (TBI)',
  545: 'trigger finger, bilateral',
  546: 'trigger finger, left',
  547: 'trigger finger, right',
  548: 'typhoid arthritis',
  549: 'ulcerative colitis',
  550: 'ureteral cancer (ureter cancer)',
  551: 'urethritis',
  552: 'urinary incontinence (loss of bladder control)',
  553: 'uterine cancer',
  554: 'vaginal cancer',
  555: 'varicocele, bilateral',
  556: 'varicocele, left',
  557: 'varicocele, right',
  558: 'varicose veins in leg, bilateral',
  559: 'varicose veins in leg, left',
  560: 'varicose veins in leg, right',
  561: 'vascular soft-tissue sarcoma, including angiosarcoma, extremities or trunk',
  562: 'vascular soft-tissue sarcoma, including angiosarcoma, head or neck',
  563: 'ventral hernia (hernia in abdomen)',
  564: 'visual impairment, including blurry vision, blindness and double vision',
  565: 'vitiligo',
  566: 'vulvar cancer',
  567: 'weak foot, bilateral',
  568: 'weak foot, left',
  569: 'weak foot, right',
  570: 'Wolff-Parkinson-White syndrome',
  571: 'wrist fracture, bilateral',
  572: 'wrist fracture, left',
  573: 'wrist fracture, right',
  574: 'wrist replacement (wrist arthroplasty), bilateral',
  575: 'wrist replacement (wrist arthroplasty), left',
  576: 'wrist replacement (wrist arthroplasty), right',
  577: 'wrist sprain, bilateral',
  578: 'wrist sprain, left',
  579: 'wrist sprain, right',
};
