import React from 'react';

const ArrowRightBlueSVG = () => (
  <svg
    className="all-link-arrow"
    width="444.819"
    height="444.819"
    viewBox="0 0 444.819 444.819"
  >
    <path
      fill="#004795"
      d="M352.025 196.712L165.885 10.848C159.028 3.615 150.468 0 140.185 0s-18.84 3.62-25.696 10.848l-21.7 21.416c-7.045 7.043-10.567 15.604-10.567 25.692 0 9.897 3.52 18.56 10.566 25.98L231.544 222.41 92.785 361.168c-7.04 7.043-10.563 15.604-10.563 25.693 0 9.9 3.52 18.566 10.564 25.98l21.7 21.417c7.043 7.043 15.612 10.564 25.697 10.564 10.09 0 18.656-3.52 25.697-10.564L352.025 248.39c7.046-7.423 10.57-16.084 10.57-25.98.002-10.09-3.524-18.655-10.57-25.698z"
    />
  </svg>
);

export default ArrowRightBlueSVG;
