export const START_NEW_APP_DEFAULT_MESSAGE = 'Start a new application';
export const CONTINUE_APP_DEFAULT_MESSAGE = 'Continue your application';
export const APP_SAVED_SUCCESSFULLY_DEFAULT_MESSAGE =
  'We’ve saved your application.';
export const FINISH_APP_LATER_DEFAULT_MESSAGE = 'Finish this application later';
export const UNAUTH_SIGN_IN_DEFAULT_MESSAGE =
  'Sign in to start your application';
export const APP_TYPE_DEFAULT = 'application';
export const APP_ACTION_DEFAULT = 'applying';
export const REVIEW_APP_DEFAULT_MESSAGE = 'Review application';
export const FILE_UPLOAD_NETWORK_ERROR_MESSAGE =
  'We\u2019re sorry. We had a connection problem. Please try again.';

export const accountTitleLabels = {
  CHECKING: 'Checking Account',
  SAVINGS: 'Savings Account',
  NOBANK: 'No Bank Account',
};
